<template>
  <div class="main-content">
    <div class="selCard mb14">
      <el-form
        size="small"
        inline
        :model="searchData"
        ref="searchForm"
        label-width="85px"
      >
        <el-form-item label="子规格名称">
          <el-input
            v-model="searchData.name"
            placeholder="输入子规格名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="handleSearch()"
            >搜索</el-button
          >
          <el-button size="small" @click="handleReset()">重置</el-button>

          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="handleCreate"
            >新增子规格</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <!-- <template #header>
        <div class="flex align-items marginBottom20">
          <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">新增子规格</el-button>
          <el-input size="medium" v-model="searchData.name" :maxlength="100" placeholder="输入子规格名称" class="marginLeft10 width300"/>
          <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch" class="marginLeft10">查询</el-button>
          <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset" class="marginLeft10">重置</el-button>
        </div>
      </template> -->
      <template slot="menu" slot-scope="{ row }">
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          >删除</el-button
        >
      </template>
    </avue-crud>

    <el-dialog
      :title="`${form.id ? '编辑' : '新增'}子规格`"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  list,
  add,
  deleteSpec,
  view,
  update,
} from "@/api/supplierGoods/specSon";
export default {
  name: "specSon",
  data() {
    return {
      searchData: {
        name: "",
        id: this.$route.query.id,
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          // {
          //   label: "ID",
          //   prop: "id",
          // },
          {
            label: "子规格名称",
            prop: "name",
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        parentId: this.$route.query.id,
        name: "",
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "子规格名称",
            prop: "name",
            maxlength: 10,
            showWordLimit: true,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入子规格名称",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        this.tableData = res.data.records;
        this.page.total = res.data.total;
      });
    },
    // 新增规格
    handleCreate() {
      // console.log('新增规格')
      this.dialogVisible = true;
    },
    // 查询规格
    handleSearch() {
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置规格
    handleReset() {
      this.searchData.name = "";
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 子规格管理
    handleEditSon(row) {
      this.$router.push({
        path: "/shop/spec/specSon",
        params: {
          id: row.id,
        },
      });
    },
    // 编辑规格
    async handleEdit(row) {
      // console.log('编辑规格', row)
      const { data } = await view(row.id);
      this.form = data;
      this.dialogVisible = true;
    },
    // 删除规格
    handleDelete(row) {
      // console.log('删除规格', row)
      this.$confirm("此操作将永久删除该规格, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteSpec(row.id).then((res) => {
            if (res.code === 200) {
              this.$message.success("删除成功!");
              this.onLoad();
            } 
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 新增/编辑规格
    submit(form, done) {
      // console.log('新增/编辑规格', form)
      if (form.id) {
        update(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("修改成功");
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
            } else {
              done();
            }
          })
          .catch(() => done());
      } else {
        add(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
            } else {
              done();
            }
          })
          .catch(() => done());
      }
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        name: "",
        parentId: this.$route.query.id,
      };
    },
  },
};
</script>

<style scoped></style>
